.salary-slider {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;
    max-width: 300px;
  }
.salary-title{
    margin-bottom: 10px;
    font-size: 0.8rem;
    color: #202020;
    font-weight: 500;
}
  .salary-range {
    color: #202020;
    opacity: 0.7;
    font-size: 0.8rem;
  }
  
  .salary-slider h3 {
    font-size: 0.8rem;
    color: #202020;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  