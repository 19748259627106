/* Base styles for desktop */
.apply-page {
  padding: 2rem 10rem;
  overflow-x: hidden;
}

.apply-page h1 {
  font-size: 2.375rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
}

.apply-page h2 {
  font-size: 1.75rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.company-logo {
  width: 80px;
  height: 80px;
  border-radius: 0.75rem;
}

.apply-page-company-name {
  margin-bottom: 0rem;
}

.apply-page h3 {
  font-size: 1.438rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.apply-page p {
  font-size: 1rem;
  font-weight: 400;
  max-width: 38rem;
  line-height: 1.4rem;
}

.apply-page-place {
  color: #202020;
  opacity: 0.6;
}

.apply-content {
  background: #fcfcfc;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.apply-content-1 {
  padding: 4rem 10rem;
}

.apply-page-company {
  margin-bottom: 1rem;
}

.apply-page-company-logo-name {
  display: flex;
  align-items: end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.apply-page-respo-what {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.apply-page-job-info-title-description {
  margin-bottom: 1.5rem;
}

.apply-page-respo ul {
  list-style-type: none;
  padding: 0;
}

.apply-page-respo ul li:before {
  content: '•';
  font-size: 3rem;
  margin-bottom: 0.3rem;
  margin-left: 1rem;
  color: #d9d9d9;
  font-weight: bold;
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: middle;
}

.apply-page-respo ul li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
}

.apply-page-respo {
  max-width: 25rem;
  padding-right: 1rem;
}

.apply-page-salary {
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.apply-page-salary-work-type {
  gap: 3rem;
  display: flex;
}

.apply-page-sal-req {
  display: flex;
  gap: 5rem;
}

.apply-page-requirement-item {
  transform-origin: center;
  margin-bottom: -1rem;
  align-items: center;
  padding: 1.4rem;
  transition: all ease-in-out 0.2s;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  background-color: #fcfcfc;
  border: solid 2px #fcfcfc;
  max-width: 70%;
}

.apply-page-requirement-item:hover {
  border: solid 2px #d2ff2f;
}

.apply-page-requirement-item:nth-child(odd) {
  transform: rotate(1.2deg);
}

.apply-page-requirement-item:nth-child(odd):hover {
  transform: rotate(0deg);
}

.apply-page-requirement-item:nth-child(even):hover {
  transform: rotate(0deg);
}

.apply-page-requirement-item:nth-child(even) {
  transform: rotate(-0.6deg);
}

.apply-page-requirement-item-text {
  margin-bottom: 0.5rem;
  color: #121212;
}

.bold {
  font-weight: bold;
}

.credential-dates {
  color: #20202088;
}

.question-and-answer {
  transform-origin: center;
  margin-bottom: 3rem;
  transition: all ease-in-out 0.2s;
}

.input-container.odd {
  transform: rotate(-0.7deg);
  transition: all ease-in-out 0.2s;
}

.input-container.even {
  transform: rotate(0.7deg);
  transition: all ease-in-out 0.2s;
}

.input-container.odd:focus-within,
.input-container.even:focus-within,
.input-container.odd:hover,
.input-container.even:hover {
  transform: rotate(0deg);
}

.apply-page-questions {
  margin-top: 3rem;
}

.answer-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  box-sizing: border-box;
  border: 1px solid #202020;
  border-radius: 0.75rem;
  transition: all ease-in-out 0.2s;
}

.numbers-input {
  height: 30px;
}

.text-input {
  height: 80px;
}

.apply-page-resume-parser {
  font-size: 1.438rem;
  font-weight: 400;
}

.upload-resume-button {
  border: none;
  background: transparent;
}

.upload-resume-button-div {
  display: flex;
  padding: 0.7rem 1.5rem;
  gap: 0.5rem;
  align-items: center;
  background: #202020;
  color: #fcfcfc;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-top: 0.7rem;
  margin-bottom: 1.4rem;
  rotate: 2deg;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.upload-resume-button-div:hover {
  rotate: 0deg;
}

.resume-upload-arrow {
  width: 1.2rem;
}

.resume-parsed-element {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.info-card {
  position: relative;
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  padding: 1.7rem;
  margin-bottom: 1rem;
  width: 80%;
  transform-origin: top;
  gap: 0.3rem;
}

.edit-button {
  border: none;
  background: #d6d6d6;
  height: fit-content;
  padding: 0.4rem 0.5rem;
  border-radius: 0.75rem;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.edit-button:hover {
  transform: scale(1.1);
}

.info-card-whole {
  display: flex;
  gap: 1rem;
  max-width: 35rem;
  align-items: center;
}

.save-button {
  background: #d2ff2f;
  color: #202020;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 0.75rem;
  margin-top: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: all ease-in-out 0.2s;
  width: 35%;
}

.credential-card {
  background-color: transparent;
  border: 1px solid transparent;
  margin-top: 1em;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  margin-bottom: 15px;
  transition: all 0.2s;
  color: #202020;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  width: 90%;
  gap: 0.2rem;
}

.credential-card-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.credential-card-whole {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.input-error {
  border: 1px solid red;
}

.apply-page-add-credential {
  border: solid 1px #202020;
  width: 70%;
  background-color: transparent;
  padding: 0.6rem 1rem;
  border-radius: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.apply-page-add-credential:hover {
  background-color: #202020;
  color: #fcfcfc;
}

.delete-button {
  padding: 0.6rem 1.2rem;
  width: 35%;
  font-size: 1rem;
  border: none;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-top: 0.5rem;
  color: #fcfcfc;
  background-color: #202020;
  transition: all ease-in-out 0.2s;
}

.confirm-delete-button {
  padding: 0.6rem 1.2rem;
  width: 35%;
  font-size: 1rem;
  border: none;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-top: 0.5rem;
  color: #fcfcfc;
  background-color: #d64545;
}

.save-button:hover,
.delete-button:hover {
  transform: rotate(2deg);
}

.apply-page-apply-button {
  border: solid 1px #202020;
  font-size: 2rem;
  padding: 0.6rem 1rem;
  border-radius: 0.75rem;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;
}

.selection-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.selection-column {
  flex: 1;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.5rem;
  height: fit-content;
}

.selection-item {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: white;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selection-item:hover {
  background-color: #e0e0e0;
}

.tag-grid {
  display: block;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 0.5rem;
}

.tag-item {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: white;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag-item:hover {
  background-color: #e0e0e0;
}

.selection-item.active,
.tag-item.active {
  background-color: #dedede;
  font-weight: 400;
}

.present-checkbox {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  max-width: 200px;
  margin-right: 1rem;
}

.credential-end-date label {
  display: block;
  margin-bottom: 5px;
}

.credential-end-date input[type="checkbox"] {
  margin-top: 5px;
}

.upload-resume-button-div.idle {
  background-color: #202020;
}

.upload-resume-button-div.uploading,
.upload-resume-button-div.parsing {
  background-color: #d2ff2f;
  color: #202020;
}

.upload-resume-button-div.completed {
  background-color: #d2ff2f;
  color: #202020;
}

.resume-complete-icon {
  width: 24px;
  height: 24px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-left-color: #202020;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thank-you-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.thank-you-message h3 {
  margin-top: 3.5rem;
  font-size: 2rem;
  font-weight: 800;
}

.thank-you-message {
  position: relative;
  width: 20rem;
  height: 15rem;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1010;
  overflow: hidden;
}

.thank-you-message canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .apply-page {
    padding: 1rem 2rem;
  }

  .apply-content-1 {
    padding: 2rem 1rem;
  }

  .apply-page h1 {
    font-size: 1.875rem;
  }

  .apply-page h2 {
    font-size: 1.5rem;
  }

  .apply-page h3 {
    font-size: 1.25rem;
  }

  .apply-page p {
    font-size: 0.875rem;
    max-width: 100%;
    line-height: 1.2rem;
  }

  .apply-page-respo-what {
    flex-direction: column;
    gap: 1rem;
  }

  .apply-page-job-info-title-description {
    margin-bottom: 1rem;
  }

  .apply-page-salary-work-type {
    flex-direction: column;
    gap: 1rem;
  }

  .apply-page-sal-req {
    flex-direction: column;
    gap: 1rem;
  }

  .info-card {
    width: 70%;
    padding-inline:1rem ;
  }

  .info-card-whole {
    flex-direction:row;
    align-items: flex-start;
    align-items: center;
  }

  .apply-page-add-credential {
    width: 100%;
  }

  .credential-card {
    width: 100%;
    box-sizing: border-box;
    padding: 2rem;
  }

  .credential-card-whole {
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
  }

  .save-button,
  .delete-button,
  .confirm-delete-button {
    width: 100%;
  }

  .apply-page-apply-button {
    font-size: 1.5rem;
    width: 100%;
  }

  .upload-resume-button-div {
    width: 100%;
    justify-content: center;
  }

  .thank-you-message {
    width: 90%;
    height: auto;
    padding: 10px;
  }

  .thank-you-message h3 {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
}
