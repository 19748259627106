/* components/JobBoard.css */
.jobboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .company-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .company-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .company-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .company-links a {
    margin: 0 10px;
    color: #007BFF;
    text-decoration: none;
  }
  
  .company-links a:hover {
    text-decoration: underline;
  }
  
  .jobboard-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .no-joboffers {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
  }
  
  .joboffers-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .joboffer-item {
    background: #fff;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .joboffer-item:hover {
    transform: rotate(2deg);
  }
  