.email-confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
}

.email-confirm-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.email-confirm-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #666;
}

.email-confirm-container button {
    padding: 10px 20px;
    font-size: 1em;
    color: #202020;
    background-color: #D2FF2F;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.email-confirm-container button:hover {
    transform: rotate(2deg);
}

@media (max-width: 600px) {
    .email-confirm-container h1 {
        font-size: 1.5em;
    }

    .email-confirm-container p {
        font-size: 1em;
    }

    .email-confirm-container button {
        font-size: 0.9em;
    }
}
