.item-list {
    display: flex;
    flex-direction: column;
  }
  
  .item-list h3 {
    font-size: 1rem;
    color: #202020;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .item-list ul {
    color: #202020;
    max-width: 20vw;  /* Limiting the width to 480px */
    padding: 0;
  }
  

  


  .item-list-li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #202020;
    font-size: 0.8rem;
    padding: 0.5rem 2rem;
    display: flex;
    border-left: solid 1px #202020;
    margin-bottom: 15px;
    transition: all 0.2s;  
    margin-bottom: 1vw;  /* Adding some gap between the items */
    word-wrap: break-word;  /* Break the word to next line if it exceeds the width */
  }

  .remove-item {
    cursor: pointer;
    color: red;
  }
  
  .item-title {
      font-size: 23px;
      color: #202020;
      font-weight: 500;
      margin-bottom: 1vw;
  }
  .add-item-textfield{
    width: 60%;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .add-item-button {
      border: none;
      border-radius: 0.75rem;
      color: #202020;
      background-color: #d4d4d4;
      cursor: pointer;
      font-size: 0.9rem;
      padding: 0.5rem;
      margin-left: 1rem;
      transition: all 0.3s;
  }

  .add-item-button:hover {
    background-color: #bbbbbb;
}

.item-delete-button{
  border: solid 1px #202020;
  border-radius: 0.75rem;
  color: #202020;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.5rem;
  margin-left: 0.6rem;
  transition: all 0.3s;
}

.item-delete-button:hover{
  color: #a73810;
  border: solid 1px #a73810;
}
  