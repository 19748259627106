.requirement-card {
    color: #202020;
    border: 1px solid #fcfcfc4a;
    border-radius: 0.75rem;;
    background-color: #FCFCFC;
    transition: all 0.2s ease-in-out;
    padding: 0.8rem 0.8rem;
    width: 12vw;
    cursor: pointer;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  }

  .requirement-card:hover{
    border-color: #202020;
  }
  
  .requirement-card h2 {
    margin: 0;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .requirement-card p {
    font-size: 0.8rem;
  }
  