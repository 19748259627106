.applicants-general {
    display: flex;
    padding: 1.5rem;
    gap: 0.87rem;
    height: 100vh; /* or any specific height */
    overflow-x: auto;
    overflow-y: hidden;
  }

  .applicants-general > * {
    flex-shrink: 0;
  }
  
  .applicants-general-content {
    display:flex;
    flex-direction: column;
    gap: 0.69rem;
    max-height: 100vh; /* Add this line */

}

  .scroll-right {
    cursor: e-resize ; /* Right arrow with higher priority */
  }
  
  .scroll-left {
    cursor: w-resize; /* Left arrow with higher priority */
  }
  

  .applicants-columns {
    display: flex;
    flex-direction: column;
    gap: 0.4rem; /* Adjust the gap between items */
  }
  

  .filtered-applicants {
    display: grid;
    gap: 20px; /* Adjust the gap between items */
    background-color: #FCFCFC;
    padding: 2rem;
    border-radius: 0.75rem;
    overflow-x: hidden; /* Prevent overflow */

  }
  .top-applicants,
  .mid-applicants {
      display: flex;
      margin-bottom: 2em;
      gap: 1.5rem;
      overflow-x: scroll;
      border-radius: 0.75rem;
      padding: 2rem;
      background-color: #FCFCFC;
      /* Remove fixed width if any */
      flex-shrink: 0; /* Prevent shrinking */
      flex-grow: 1; /* Allow it to grow */
      box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);

  }
  

.top-applicants::-webkit-scrollbar,
.mid-applicants::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Safari and Chrome */
}

.applicants-scroll-container{
 display: flex;
 width: 97%;
 justify-content: space-between;
 transform: translateY(-6rem);
 padding-left: 1rem;
 padding-right:1rem;
}

.scroll-area {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 10;
}

.scroll-area.left {
  left: 0;
  cursor: w-resize;
}

.scroll-area.right {
  right: 0;
  cursor: e-resize;
}

.scroll-button{
  background-color: #FCFCFC;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}


/* Title styles */
.section-title {
    font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
  margin-top: 0.5rem;
  gap: 1rem;
}

.divider {
  margin-top: 1em;
}
  
  .page-name{
    color: #202020;
    font-size: 1.3rem;
    margin-bottom: 0.4rem;
  }
  
  .view-options {
    display: flex;
    align-items:baseline;
    margin-bottom: 3px;
  }
  
  .view-option {
    color: #FCFCFC;
    font-size: 30px;
    opacity: 0.7;
    cursor: pointer;
    margin-right: 20px;
    text-decoration: none;
  }

  .divider{
    margin-top: 1em;
  }
  
  .view-option-selected {
    color: #FCFCFC;
    font-size: 30px;
    opacity: 1;
    cursor: pointer;
    margin-right: 30px;
    text-decoration: none;
  }
 

  
  .search-bar,
  .filter-states,
  .filter-experience,
  .filter-distance,
  .filter-requirements {
    margin-top: 1em;
    font-size: 1.2em;
    margin-left: 0.6em;
  }
  
  .plus-minus {
    display: flex;
    align-items: center;
  }
  
  .plus-minus button {
    margin: 0 1em;
    background: none;
    border: none;
    font-size: 1.2em;
    color: #fcfcfc;
    cursor: pointer;
  }
  
  .search-bar-input {
    color: #000000;
    font-size: 1.2em;
  }

  .filter-content-applicants {
    width: 16rem; /* Fixed width for the filter section */
    flex-shrink: 0; /* Prevent shrinking */
    margin-top: 10.7rem;
    border-radius: 0.75rem;
    margin-bottom: 2.2rem;
    margin-right: 2rem;
    top: 16.4rem;
    color: #202020;
    background-color: #FCFCFC;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    height: fit-content;
}

.filter-content-applicants h1,
.filter-content-applicants h2 {
  font-weight: bold;
  margin-top: 1em;
}

.filter-content-applicants p {
  margin-top: 2em;
  font-weight: 200;
  font-size: 1em;
}

  

  .filter-state input[type="checkbox"] {
    margin-right: 0.5em;
  }

  .job-copy-link-button {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-items: center;
    transition: all 0.5s ease;   
    background-color: #D2FF2F; 
    border-radius: 0.75rem;
    border: none;
    cursor: pointer;
    padding-inline: 0.7rem;
    padding-block:0.7rem
  }

  .job-copy-link-button:hover {
    transform: rotate(2deg); /* initial transform for animation start */
    transition: visibility 0.5s, opacity 0.5s, transform 0.5s;
    }
  
  .filter-content-title{
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .job-copy-link-button img {
    transition: transform 0.3s ease;
  }
  
  .job-copy-link-button.copied img {
    transform: rotate(20deg);
  }
  
  .apply-link-title{
    margin-right: 1em;
    font-size: 1.2em;
    margin-block: 0rem;
    font-weight: 400;
    color: #202020;
  }
  
  .link-copied-message{
    font-size: 1.2em;
    font-weight: 400;
    color: #202020;
    margin-top: 0.2rem;
  }
  .copy-icon{
    width: 1.2em;
    height: auto;
  }

  .apply-filters-button{
    margin-top: 1em;
    padding: 0.5em 1em;
    font-size: 1rem;
    border: none;
    border-radius: 0.75rem;
    background-color: #D2FF2F;
    color: #202020;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 1rem;
  }

  .clear-filters-button{
    margin-top: 1em;
    padding: 0.5em 1em;
    border: none;
        border-radius: 0.75rem;

    background-color: #fcfcfc6e;
    color: #202020;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    filter: invert(1);
  }

  .filter-section{
    padding-inline: 1.3rem;
    margin-block: 1.3rem;
  }

  .slider-container {
    width: 100%; /* Ensure the container takes up the full width */
    overflow: hidden; /* Prevent overflow */
  }
  

  .filter-container h3{
    font-size: 1rem;
    font-weight: 500;
  }
  
  .filter-container {
    margin-bottom: 2em;
  }
  
  
  .filter-item {
    margin-bottom: 15px; /* Spacing between each filter item */
  }
  

  
  .filter-item input[type="number"] {
    width: 60px; /* Adjust to your liking */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 0.75rem;
    text-align: center; /* Center the number */
  }
  
  .filter-item button {
    padding: 5px 8px;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
  }
  
  
  .counter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border-radius: 0.75rem;
}

.counter-container label {
  font-size: 0.8rem;
    color: #202020;
}

.counter-button-applicants {
  color: #202020;
  background-color: transparent;
  border: none;
  border-radius: 0.75rem;
  font-size: 1.3rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.counter-button-applicants:hover {
  background-color: #D2FF2F; /* a slightly darker shade for hover effect */
}

.counter-number-applicants {
    width: 5em;
    font-size: 1rem;
    padding: 0.5em;
    margin: 0 1em;
    text-align: center;
        border-radius: 0.75rem;

    border: 1px solid #FCFCFC;
    color: #FCFCFC;
    background-color: #202020;
}

.counter-number-applicants:focus {
    outline: none;
    border: 2px solid #D2FF2F;
}

.application-details-h3{
  margin-bottom: 1.5rem;
}

.page-selection {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.page-selection-button{
  padding: 0.4rem 1.2rem;
  color: #202020;
  background: transparent;
  border: solid 2px #202020;
  border-radius: 0.75rem;;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.page-selection-button:hover {
  background: #d4d4d437;
  color: #202020;
  transform: rotate(2deg);
}

.page-selection-button.active {
  background-color: #202020; /* Bootstrap primary color for example */
  color: #FCFCFC;
}

.page-selection-button.active:hover {
  transform: rotate(2deg);
}

.counter-container {
  display: flex;
  align-items: center;
  background: #20202017; /* Change this to match your actual design color */
  border-radius: 20px; /* Rounded corners as per the design */
  padding: 0.3rem; /* Or whatever padding matches your design */
}

.counter-label {
  font-weight: 400; /* If label is bold in your design */
  font-size: 1rem;
}

.counter-controls {
  display: flex;
  align-items: center;
  gap:0.5rem;
}

.counter-button-applicants {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px; /* Adjust as needed */
  font-size: 1.5rem; /* Adjust as needed */
  color: #333; /* Adjust button color as needed */
}

/* You may want to add :hover and :focus styles for better UX */
.counter-button-applicants:hover,
.counter-button-applicants:focus {
  background-color: #ddd; /* Example hover/focus color */
}

.counter-number-display {
  padding: 5px 10px; /* Adjust as needed */
  font-size: 1rem; /* Adjust as needed */
  background: #FFFFFF; /* For the white background */
  border-radius: 10px; /* To match the design */
  display: inline-block;
  width: 40px; /* Or 'auto' if you want the div to grow with content */
  text-align: center;
}

/* Adjust the decrement and increment buttons specifically if needed */
.counter-decrement {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.counter-increment {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.applicants-stats-section {
  display: flex;
  justify-content: flex-start;
  gap: 1.1rem;
  margin: 20px 0;
}

.applicant-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 0.4rem 1rem;
  gap: 0.5rem;
  border-radius: 0.75rem;
  text-align: start;
  font-weight: 600;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}

.total-applicants-card {
  background-color: #202020;
  color: #fff;
  
}

.average-score-card {
  color: #202020;
  border: solid 5px #202020  ;
}

.applicant-card .number {
  font-size: 2em;
  font-weight:600;
  margin: 0;
  line-height: 0%;
}

.applicant-card .text {
  font-size: 0.7em;
  margin: 0;
}

.applicant-card p {
  margin: 0;
}

.job-title{
  color: #202020;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 118.38%; /* 1.33175rem */
}

.job-title-back{
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.job-back{
  width: 1rem;
height: 1rem;
}

.applicants-general-statuses{
display: inline-flex;
align-items: center;
gap: 0.5rem;
border-radius: 0.375rem;
background: #FCFCFC;
padding: 0.35rem;
/* Drop Shadow Normal */
box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
width: fit-content;
}

.applicants-general-status{
  display: flex;
  width: fit-content;
  padding: 0.375rem 0.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.375rem;
background: #FCFCFC;
border: none;
transition: all 0.2s ease;
}

.applicants-general-status:hover{
  cursor: pointer;
  background-color:#20202010 ;
}

.applicants-general-status.active {
  background-color: #20202012; /* Or any color you prefer */
  font-weight: bold;
}

.applicants-general-status.active:hover{
  cursor: pointer;
  background-color:#20202020 ;
}

.applicants-general-status-title-icon{
  display: flex;
align-items: center;
gap: 0.625rem;
}

.applicants-general-status-title{
  color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.88788rem */
}

.applicants-general-status-icon{
  width: 1rem;
height: 1rem;
}

.applicants-general-status-count{
  display: flex;
width: fit-content;
padding: 0.125rem 0.25rem;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 0.625rem;
flex-shrink: 0;
border-radius: 0.375rem;
background: #202020;
}

.applicants-general-status-count-number{
  align-self: stretch;
  color: #FCFCFC;
text-align: center;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: 118.38%; /* 0.88788rem */
}

.applicants-general-status-divider{
  width: 0.03125rem;
height: 1.625rem;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-list-view{
  display: flex;
  gap: 1rem;
  height: 100%;
  overflow-y:hidden;

}

.applicants-general-list-search{
  display: flex;
width: 17.6875rem;
height: 100%;
padding-block: 1.0625rem;
flex-direction: column;
align-items: center;
gap: 1.3125rem;
border-radius: 0.375rem;
background: #FCFCFC;
overflow-y: scroll;
overflow-x: hidden;

/* Drop Shadow Normal */
box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
}

.applicants-general-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  padding-inline: 1.12rem;
}
.applicants-general-search{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.0625rem;
padding-inline: 1.12rem;
width: 100%;
}

.applicants-general-search-input{
  display: flex;
padding: 0.25rem 0.375rem;
align-items: flex-start;
gap: 0.625rem;
align-self: stretch;
border-radius: 0.375rem;
border: 0.5px solid #D8D8D8;
width: 100%;
background: #FCFCFC;
}

.applicants-general-search-title{
  color: rgba(32, 32, 32, 0.65);
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.applicants-general-list-applicant{
  display: flex;
padding: 0.8125rem 0.875rem;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 0.375rem;
background: #FCFCFC;
transition: all 0.2s ease;
}

.applicants-general-list-divider-view{
  width: 100%;
height: 0.03125rem;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-list-applicant:hover{
  cursor: pointer;
  background-color: #20202010;
}

.applicants-general-list-applicant.active{
  background-color: #20202012;
}

.applicants-general-list-applicant.active:hover{
  background-color: #20202020;

}

.applicants-general-list-applicant-pic-info{
  display: flex;
align-items: center;
gap: 0.625rem;
}

.applicants-general-list-applicant-pic{
  width: 1.9375rem;
  height: 1.9375rem;
  border-radius: 0.375rem;
border: 1px solid #202020;
background: #202020;
}

.applicants-general-list-applicant-profile-pic{
  width: 2.15894rem;
height: 2.15894rem;
flex-shrink: 0;
border-radius: 0.75rem;
}

.applicants-general-list-applicant-info{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.125rem;
}

.applicants-general-list-applicant-name{
  color: #202020;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 118.38%; /* 1.03581rem */
text-wrap: nowrap;
}

.applicants-general-list-applicant-applydate{
  color: #202020;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.73988rem */
}

.applicants-general-list-applicant-score{
  display: flex;
width: 1.875rem;
height: 1.8125rem;
padding: 0.375rem;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.375rem;
background: #202020;
}

.applicants-general-list-applicant-score-number{
  height: 1.0625rem;
  flex-shrink: 0;
  align-self: stretch;
  color: #FCFCFC;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: center;
}

.applicants-general-list-divider{
  width: 14.75rem;
height: 0.03125rem;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-view{
  display: flex;
align-items: start;
border-radius: 0.375rem;
background: #FCFCFC;

/* Drop Shadow Normal */
box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
height: 100%;
overflow: hidden; /* Add this line to prevent overall overflow */
}

.applicants-general-view-left{
  display: flex;
height: 100%;
padding-block: 1.5625rem 1.5625rem ;
flex-direction: column;
align-items: flex-start;
gap: 1.25rem;
width: 18rem;
}

.applicants-general-view-left-info{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.8125rem;
align-self: stretch;
padding-inline: 1.0625rem 1.875rem;
}

.applicants-general-view-left-info-pic-name{
  display: flex;
justify-content: flex-end;
align-items: flex-start;
gap: 0.625rem;
}

.applicants-general-view-left-pic{
  display: flex;
  width: 3.125rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
border: 1px solid #202020;
background: #202020;
}

.applicants-general-view-left-name{
  color: #202020;
font-family: Poppins;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 118.38%; /* 1.47975rem */
display: flex;
flex-direction: column;
}

.applicants-general-view-left-info-element{
  display: flex;
align-items: center;
gap: 0.375rem;
align-self: stretch;
}

.applicangs-general-view-left-info-element-icon{
  width: 1rem;
height: 1rem;
}

.applicants-general-view-left-info-element-text{
  color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 10rem;
}

.applicants-general-view-left-info-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.applicants-general-view-left-info-button.disabled:hover {
  /* Remove any hover effects */
}

.applicants-general-view-left-info-button{
  display: flex;
width: fit-content;
padding: 0.1875rem 0.5625rem;
justify-content: center;
align-items: center;
gap: 0.375rem;
border-radius: 0.375rem;
background-color: #EDEDED;
border: none;
transition: all 0.3s ease;
}

.applicants-general-view-left-info-button:hover{
  background-color: #e0e0e0;
  cursor: pointer;
}

.applicants-general-view-left-info-button-icon{
  width: 1rem;
height: 1rem;
flex-shrink: 0;
}

.applicants-general-view-left-info-button-text{
  color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.applicants-general-view-left-info-buttons{
  display: flex;
align-items: flex-start;
gap: 0.625rem;
align-self: stretch;
flex-wrap: wrap;
}

.applicants-general-view-left-divider{
  width: 100%;
height: 0.03125rem;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-view-left-score-title{
  color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.applicants-general-view-left-score-req{
  display: flex;
align-items: flex-start;
padding-inline: 1.0625rem;
gap: 1rem;
}

.applicants-general-view-left-score-scores{
  display: flex;
align-items: flex-start;
gap: 0.375rem;
}

.applicants-general-view-left-score-main{
  display: flex;
width: 2.6875rem;
height: 2.625rem;
padding: 0.5rem;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.375rem;
background: rgba(32, 32, 32, 0.78);
}

.applicants-general-view-left-score-main-number{
  color: #FCFCFC;
  font-family: Poppins;
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.applicants-general-view-left-score-element-title{
  color: rgba(32, 32, 32, 0.70);
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.applicants-general-view-left-score-element-number{
  color: #202020;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.applicants-general-view-left-score-element{
  height: fit-content;
}

.applicants-general-view-left-score-subscores{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.1875rem;
}

.applicants-general-view-left-buttons{
  display: flex;
  flex-direction: column;
  padding-inline: 1.0625rem 1.875rem;
  gap: 0.5rem;
}

.applicants-general-view-left-buttons-top{
  display: flex;
  gap: 0.5rem;
}

.applicants-general-view-left-reject-button{
  display: flex;
padding: 0.25rem 0.625rem;
justify-content: center;
align-items: center;
gap: 0.375rem;
border-radius: 0.375rem;
border: none;
background: #202020;
color: #FCFCFC;
transition: all 0.2s ease;

}

.applicants-general-view-left-reject-button:hover{
  cursor: pointer;
  background: #414141;
}

.applicants-general-view-left-reject-button-icon{
  width: 1rem;
height: 1rem;
transition: all 0.2s ease;
}

.applicants-general-view-left-reject-button:hover .applicants-general-view-left-reject-button-icon{
  transform: translateY(0.1rem);
}

.applicants-general-view-left-buttons-text{
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
text-wrap:nowrap
;}


.applicants-general-view-left-promote-button{
  display: flex;
padding: 0.25rem 0.625rem;
justify-content: center;
align-items: center;
gap: 0.375rem;
border-radius: 0.375rem;
background: #D2FF2F;
border: none;
color: #202020;
transition: all 0.2s ease;


}

.applicants-general-view-left-promote-button:hover{
  cursor: pointer;
  background: #e4ff81;
}

.applicants-general-view-left-promote-button-icon{
  width: 1rem;
height: 1rem;
transition: all 0.2s ease;
}

.applicants-general-view-left-promote-button:hover .applicants-general-view-left-promote-button-icon{
  transform: translateY(-0.1rem);
}

.applicants-general-view-left-next-button{
  display: flex;
padding: 0.25rem 0.625rem;
justify-content: center;
align-items: center;
gap: 0.375rem;
border-radius: 0.375rem;
background: #EDEDED;
border: none;
color: #202020;
transition: all 0.2s ease;
width: fit-content;
}

.applicants-general-view-left-next-button:hover{
  cursor: pointer;
  background: #f1f1f1;
}

.applicants-general-view-left-next-button-icon{
  width: 1rem;
height: 1rem;
transition: all 0.2s ease;
}

.applicants-general-view-left-next-button:hover .applicants-general-view-left-next-button-icon{
  transform: translateX(0.1rem);
}

.applicants-general-view-middle{
  display: flex;
width: 30.0625rem;
height: 100%;
padding-block: 1.5625rem;
flex-direction: column;
align-items: flex-start;
gap: 2rem;
overflow-y: auto;
}

.applicants-general-middle-credential-sections{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 1.3125rem;
align-self: stretch;
padding-inline: 1.875rem;
}

.applicants-general-middle-credential-section{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.625rem;
align-self: stretch;
}

.applicants-general-middle-credential-section-title{
  color: #202020;
font-family: Poppins;
font-size: 0.8125rem;
font-style: normal;
font-weight: 600;
line-height: 118.38%; /* 0.96181rem */
align-self: stretch;
}

.applicants-general-middle-credential{
  display: flex;
padding: 1rem 1.1875rem;
flex-direction: column;
align-items: flex-start;
gap: 0.5rem;
align-self: stretch;
border-radius: 0.375rem;
background: #FCFCFC;

/* Drop Shadow Normal */
box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
width: 100%;
}

.applicants-general-middle-credential-info{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.25rem;
}

.applicants-general-middle-credential-title{
  color: #202020;
font-family: Poppins;
font-size: 0.9375rem;
font-style: normal;
font-weight: 600;
line-height: 118.38%; /* 1.10981rem */
align-self: stretch;
}

.applicants-general-middle-credential-company{
  color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.88788rem */
align-self: stretch;
}

.applicants-general-middle-credential-date{
  color: rgba(32, 32, 32, 0.70);
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.88788rem */
align-self: stretch;
}

.applicants-general-middle-credential-tags{
  display: flex;
align-items: flex-start;
gap: 0.5rem;
flex-wrap: wrap;
}

.applicants-general-middle-credential-tag{
  display: flex;
width: fit-content;
padding: 0.25rem 0.3125rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.375rem;
background: #F3F3F3;
color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.88788rem */
}

.applicants-general-middle-crendetial-view-all{
  display: flex;
padding: 0.375rem 0.5rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.375rem;
border: 1px solid rgba(32, 32, 32, 0.36);
color: rgba(32, 32, 32, 0.36);
background-color: transparent;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.88788rem */
transition: all 0.2s ease;
}

.applicants-general-middle-crendetial-view-all:hover{
  cursor: pointer;
  background-color: rgba(32, 32, 32, 0.06);
}

.applicants-general-view-divider{
  width: 0.03125rem;
height: 100%;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-view-middle-internal-divider{
  width: 100%;
height: 0.03125rem;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-view-middle-requirements-section,
.applicants-general-view-middle-questions-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  align-self: stretch;
  padding-inline: 1.875rem;
}

.applicants-general-view-middle-requirements{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.applicants-general-view-middle-questions-answers{
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.applicants-general-view-middle-requirement {
  display: flex;
  width: 100%;
  padding: 1.1875rem 1.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.375rem;
  background: #FCFCFC;
  box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
}

.applicants-general-view-middle-requirement-text {
  color: #202020;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.applicants-general-view-middle-question-answer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.625rem;
  border-radius: 0.375rem;
}



.applicants-general-view-middle-question-text {
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.applicants-general-view-middle-answer-text {
  color: #202020;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.applicants-general-view-right{
  display: flex;
width: 17.3125rem;
height: 100%;
padding-block: 1.5625rem;
flex-direction: column;
align-items: flex-start;
gap: 1.37rem;
}

.applicants-general-view-right-title-filter{
  display: flex;
  align-items: center;
  gap: 8.0625rem;
  padding-inline: 1.875rem;
}

.applicants-general-view-right-title{
  color: #202020;
font-family: Poppins;
font-size: 0.8125rem;
font-style: normal;
font-weight: 600;
line-height: 118.38%; /* 0.96181rem */
}

.applicants-general-view-right-filter-button{
  display: flex;
width: 2.75rem;
align-items: center;
gap: 0.125rem;
background-color: transparent;
border: none;
justify-content: center;
transition: all 0.2s ease;
}

.applicants-general-view-right-filter-button:hover{
  cursor: pointer;
  transform: translateY(-0.1rem);
}

.applicants-general-view-right-filter-button-icon{
  width: 1rem;
height: 1rem;
flex-shrink: 0;
}

.applicants-general-view-right-filter-button-text{
  color: #202020;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.73988rem */
}

.applicants-general-view-right-divider{
  width: 100%;
height: 0.03125rem;
background: rgba(32, 32, 32, 0.07);
}

.applicants-general-view-right-events-section{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.9375rem;
padding-inline: 1.875rem;
width: 100%;
}

.applicants-general-view-right-event{
  display: flex;
align-items: center;
gap: 0.6875rem;
align-self: stretch;
}

.applicants-general-view-right-event-element{
  display: flex;
padding: 0.375rem 0.75rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.375rem;
background: #FCFCFC;
box-shadow: 0px 4px 9.3px 0px rgba(0, 0, 0, 0.15);
}

.applicants-general-view-right-event-element-icon{
  width: 1rem;
height: 1rem;
}

.applicants-general-view-right-event-element-text{
  color: #202020;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.73988rem */
}

.applicants-general-view-right-event-date{
  width: 4.25rem;
  color: rgba(32, 32, 32, 0.70);
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.73988rem */
}

.applicants-general-view-right-event-bottom{
  display: flex;
align-items: center;
gap: 0.625rem;
}

.applicants-general-view-right-event-element-note-type{
  display: flex;
padding: 0.9375rem 0.75rem;
flex-direction: column;
align-items: flex-start;
gap: 0.625rem;
border-radius: 0.375rem;
background: #FCFCFC;
box-shadow: 0px 4px 9.3px 0px rgba(0, 0, 0, 0.15);
}

.applicants-general-view-right-event-element-note{
  width: 6.375rem;
  color: rgba(32, 32, 32, 0.70);
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.73988rem */
}

.circle-container {
  display: flex;
  gap: 4px;
  margin-top: 4px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e0e0e0;
  border: 1px solid #f3F3F3;
}

.circle.filled {
  background-color: #202020;
  border-color: #202020;
}

.applicants-general-view-left-score-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.score-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(32, 32, 32, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirmation-modal {
  background: #FCFCFC;
  border-radius: 0.375rem;
  padding: 1.5rem;
  box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
  max-width: 90%;
  width: 400px;
}

.confirmation-modal-message {
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 118.38%;
  margin-bottom: 1rem;
}

.confirmation-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.625rem;
}

.confirmation-modal-button {
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 118.38%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.confirmation-modal-button.cancel {
  background: #EDEDED;
  color: #202020;
  border: none;
}

.confirmation-modal-button.confirm {
  background: #202020;
  color: #FCFCFC;
  border: none;
}

.confirmation-modal-button:hover {
  opacity: 0.9;
}

.additional-credentials {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.additional-credentials.expanded {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  opacity: 1;
  width: 100%;
}

.applicants-general-middle-credential {
  margin-bottom: 0.625rem;
}

.applicants-general-middle-credential:last-child {
  margin-bottom: 0;
}

.applicants-general-middle-crendetial-view-all {
  margin-top: 0.625rem;
}

.applicants-general-statuses-apply-link{
  display: flex;
  gap: 0.62rem;
}

.applicants-general-copy-apply-link-button{
  display: flex;
padding: 0.375rem 0.8125rem;
align-items: flex-start;
gap: 0.625rem;
flex-shrink: 0;
border-radius: 0.375rem;
background: #202020;
height: fit-content;
align-self: center;
border: none;
/* Drop Shadow Normal */
box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
transition: all 0.3s ease;
}

.applicants-general-copy-apply-link-button:hover{
  cursor: pointer;
  background: #303030;
}

.applicants-general-copy-apply-link-button-icon{
  width: 1.0625rem;
height: 1rem;
}

.applicants-general-copy-apply-link-button-text{
  color: #FCFCFC;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 118.38%; /* 0.88788rem */
}

.applicants-general-view-right-filter-dropdown {
  position: absolute;
  right: 1.875rem;
  top: 2.5rem;
  background: #FCFCFC;
  border-radius: 0.375rem;
  box-shadow: 0px 4px 31.8px 0px rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
  z-index: 10;
}

.applicants-general-view-right-filter-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0;
  color: #202020;
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 400;
}

.applicants-general-view-right-filter-option input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #202020;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.applicants-general-view-right-filter-option input[type="checkbox"]:checked {
  background-color: #202020;
  border-color: #202020;
}

.applicants-general-view-right-note-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1.875rem;
  margin-top: 1rem;
}

.applicants-general-view-right-note-textarea {
  width: 100%;
  min-height: 5rem;
  padding: 0.5rem;
  border: 1px solid rgba(32, 32, 32, 0.2);
  border-radius: 0.375rem;
  font-family: Poppins;
  font-size: 0.75rem;
  resize: vertical;
}

.applicants-general-view-right-note-button {
  align-self: flex-end;
  padding: 0.375rem 0.75rem;
  background: #202020;
  color: #FCFCFC;
  border: none;
  border-radius: 0.375rem;
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.applicants-general-view-right-note-button:hover {
  background-color: #303030;
}

.filter-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #202020;
}