.profile-picture-container {
  background-color: #FCFCFC;
  color: #202020;
  font-size: 3rem;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.37rem;
  font-weight: bold;
  overflow: hidden; /* Ensures the image fits within the container */
  box-sizing: border-box; /* Include border in the width and height calculations */
  border: solid 3px #FCFCFC; /* Increase the border width */
}



.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image is cropped to fit the container */
}

.highlight-border {
  border: 3px solid #202020; /* Change this color as needed */
}