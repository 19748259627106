.save-changes-button{
    width: 70%;
    padding: 0.5rem;
    border-radius: 0.75rem;
    background-color: transparent;
    border: solid 1px #202020;
    color: #202020;
    transition: background-color 0.3s, color 0.3s; /* for smooth transition */
  }
  
  .save-changes-button.modified {
    background-color: #D2FF2F; /* new background color when modified */
    color: #121212; /* new text color when modified */
    border: solid 1px #D2FF2F; /* optional: change border color if you like */
    cursor: pointer;
  }
  
  .save-changes-button:disabled {
    background-color: transparent;
    color: #3f3f3f;
    border: solid 1px #3f3f3f;
    cursor: not-allowed;
  }
  