.dropdown-button {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 1;
  padding-top: 10px;
  gap: 15px;
  flex-wrap: wrap;
  opacity: 0; /* start with dropdown content fully transparent */
  transform: translateY(-20px); /* start with dropdown content slightly above its final position */
  transition: opacity 0.5s ease, transform 0.5s ease; /* animate opacity and position */
  pointer-events: none; /* prevent clicks when dropdown is not open */
}
.dropdown-button.open .dropdown-content {
  display: flex; /* changed from flex to grid for two-column layout */
  flex-direction: column;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}


.requirements-dropdown-button {
  color: #202020;
  background-color: #D2FF2F;
  border: none;
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.75rem;;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.requirements-dropdown-button:hover {
  transform: rotate(2deg);
}

.back-button {
  color: #202020;
  background-color: transparent;
  border: solid 1px #20202015;
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.75rem;
  margin-bottom: 15px;
  margin-left: 1vw;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

}

.back-button:hover {
  transform: rotate(2deg);
}