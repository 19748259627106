.state-active{
    color: #D2FF2F;
    background-color:#202020 ;
    padding-inline: 1rem;
    padding-block: 0.3rem;
    border-radius: 0.75rem;
    font-size: 0.8rem;
}



.state-paused{
    color: #D2D200;
    background-color:#202020 ;
    padding-inline: 1rem;
    padding-block: 0.3rem;
    border-radius: 0.75rem;
    font-size: 0.8rem;}

.state-closed{
    color: #C74F4F;
    background-color:#202020 ;
    padding-inline: 1rem;
    padding-block: 0.3rem;
    border-radius: 0.75rem;
    font-size: 0.8rem;}