.application-left {
    width: 70%;
    align-items:flex-start;
    padding-right: 1rem;
 }


 .application-right {
  display: flex;
  flex-direction: column;
  height: 100%; /* Set the height to allow children to grow */
  width: 40%; /* Set the width as required */
    }
    
    .application-right .long-text-field {
      width: 100%; /* Occupy the full width of the parent */
      margin-bottom: 1rem; /* Keep the space at the bottom */
    }

    .application-info-general{
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
    }
 
    .application-general {
      background-color: #202020;
      border-radius: 0.75rem;
      border: solid 1px #202020; /* Change the border color on hover */
      padding: 1.4rem;
      display: flex;
      cursor: pointer;
      transition: all 0.3s ease;
      color: #FCFCFC;
      box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.25);
    }
    
    .application-general:hover {
      border: solid 1px #D2FF2F; /* Change the border color on hover */
      transform: rotate(2deg); /* Moves the card up by 5px */
      box-shadow: 0px 10px 20px rgba(210, 255, 47, 0.5); /* Deepens the shadow */
    }
    
    


  .application-name {
    font-size: 1.2em;
    margin-bottom: 0.2em;
    font-weight: 400; /* Medium weight */
    text-decoration: none;
    line-height: 60%;
    color: #FCFCFC;
  }
  
  .application-date {
    font-size: 0.9em;
    font-weight: 300; /* Light weight */
    color: rgba(255, 255, 255, 0.7); /* Assuming your text color is white. Change as needed. */
  }
  
  .application-role {
    font-size: 17px;
    font-weight: 400; /* Regular weight */
    margin-bottom: 0.5rem;
  }

  .application-status {
    font-size: 0.9em;
    font-weight: 600; /* Medium weight */
    color: #202020;
    padding-inline: 9px;
    padding-block: 4px;
    border-radius: 0.75rem;
    background-color: #C2FE96;
    width: fit-content;
  }

  .application-req-answ-cover{
    color: #FCFCFC;
    font-size: 1em;
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  
  .application-requirements,
  .application-answers,
  .application-cover-letter{
    opacity: 0.7;
    margin-bottom: 0.3rem;
    text-align: center;
    align-content: center;
    line-height: 1.7;
    font-weight: bold;
    white-space: nowrap;
  }
  
  .full-opacity{
    opacity: 1 !important;
  }
  
  .fulfilled-checkmark{
    height: 1.4vw;
    margin-bottom: -0.2vw;
    margin-left: 0.5vw;
  }

  .pic-info-general{
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
  }