.section {
    margin-bottom: 6rem;
    width: 90%;
  }
  
.title {
    font-size: 2rem;
    font-weight: 400;
    color: #202020;
}

.section-header{
  display: flex;
}