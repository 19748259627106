.personal-info {
    color: #202020;
    align-self: flex-end;
    margin-bottom: 1rem;
    position: relative;
    top: 20px;
    text-align: start;

  }
  
  .name {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 0;
    line-height: 110%;
  }
  
  .other-info {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.3vw;
  }

  .profile-pic-name{
    display: flex;
    gap: 1rem;
  }

  .other-info-gender{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .name-linkedin {
    display: flex;
    align-items: center;
  }
  
.cv-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    opacity: 0.7;
    transition: transform 0.3s ease, opacity 0.3s ease;
    margin-top: 1rem;
}

.cv-button:hover {
    transform: scale(1.1);
    opacity: 1;
}

.cv-svg {
    width: 24px;
    height: 24px;
}

.buttons {
    display: flex;
    gap: 0.2rem;
}


  