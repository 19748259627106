.requirement-item {
    display: flex;
    align-items: center;
    padding: 1.4rem;
    margin-bottom: 1rem;
    transition: all 0.2s;
    color: #202020;
    border: solid 1px #20202021;
    border-radius: 0.75rem;
    background-color: #FCFCFC;
    font-size: 0.9rem;
  }

  .requirement-item-whole{
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

.academic-category-requirement{
    font-size: 20px;
}
  
  .requirement-type {
    color: #202020;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 3px;
  }
  
  .requirement-delete {
    border: solid 1px #20202021;
    padding: 0.5rem 1rem;
    background-color: transparent;  /* You can change this to a color that matches your theme */
    color: #202020;
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 0.75rem;;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .requirement-delete:hover {
    color: #ff4500;
    border: solid 1px #ff4500;
  }
  
  