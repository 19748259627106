.selection-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; 
  width: fit-content;
  margin-bottom: 1.5rem;
  gap: 0.6rem;
}

.selection-field.align-right {
  justify-content: flex-end;
}

.selection-button {
  padding: 0.3rem 1rem;
  color: #202020;
  background: none;
  border: 1px solid #202020;
  border-radius: 0.75rem;;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.selection-button:hover {
  background: #202020;
  color: #FCFCFC;
  transform: rotate(4deg);
}

.selection-button.selected{
  background: #202020;
  color:#D2FF2F;
}

