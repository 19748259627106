.job-offers-page {
  display: flex;
  padding: 1.5rem;
  gap: 2rem;
  height: 100vh; /* or any specific height */

  }
  
  .joboffers-content {
    display: flex;
    flex-direction: column;
    flex: 3;
    min-width: 0;
  }
  
  .joboffers-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    color: #202020;
  }
  
  .divider {
    margin-bottom: 2em;
  }
  
  .filter-content {
    margin-top: 12rem;
    border-radius: 0.75rem;
    padding-inline: 1.5rem;
    margin-bottom: 7.4rem;
    flex: 0 0 16em;
    height: fit-content;
    margin-right: 3rem;
    padding-bottom: 2rem;
    color: #202020;
    background-color: #FCFCFC;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  }
  
  .active-filters-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .filter-content h1,
  .filter-content h2 {
    font-weight: bold;
    margin-top: 1em;
  }
  
  .filter-content p {
    margin-top: 2em;
    font-weight: 200;
    font-size: 1em;
  }
  
  .filter-title {
    font-size: 1.3em;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: #202020;
  }
  
  .filter-states {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    color: #202020;
  }
  
  .filter-state {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }
  
  .filter-state input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    border: solid 0.7px #202020;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 1em;
    transition: all 0.3s;
  }
  
  .filter-state input[type="checkbox"]:checked {
    background-color: transparent;
    border: solid #fcfcfc;
  }
  
  .filter-state .active {
    color: #D2FF2F;
  }
  
  .filter-state .paused {
    color: #D2D200;
  }
  
  .filter-state .closed {
    color: #C74F4F;
  }
  
  a {
    text-decoration: none;
  }
  
  .job-title-refresh {
    display: inline-flex;
    align-items: baseline;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 2em; /* spacing after job offers */
  }
  
  .pagination-button {
    background-color: transparent;
    border: 1px solid #202020;
    border-radius: 0.75rem;
    color: #202020;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination-button:hover {
    background-color: #202020;
    color: #FCFCFC;
  }
  
  .current-page {
    font-size: 1rem;
    color: #202020;
    margin-left: 10px;
    margin-bottom: 0;
  }
  
  .active-filters-section {
    background-color: #FCFCFC;
    padding-top: 1rem;
    padding-inline: 1.75rem;
    align-items: start;
    border-radius: 0.75rem;
    height: 6rem;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
  }
  
  .active-filter-tag {
    display: inline-block;
    background-color: #eef1f5;
    color: #333;
    border-radius: 0.75rem;
    padding: 5px 10px;
    margin-right: 10px;
    font-size: 0.85em;
    border: solid 1px #121212;
    background-color: transparent;
    color: #121212;
  }
  
  .active-filter-tag .remove-filter-btn {
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .job-offers-section {
    border-radius: 0.75rem;
    padding-block: 1rem;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
  
  /* Header CSS */
  .job-offers-header {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 2.3rem; /* Match the horizontal padding with joboffer */
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    background-color: #202020;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  }
  
  .job-offers-header-element {
    color: #FCFCFC;
    font-size: 1rem;
    flex: 1;
    text-align: center;
  }
  
  .free-version-section p {
    font-size: 0.8rem;
  }
  
  .free-version-section {
    background-color: #121212;
    padding-block: 1rem;
    padding-inline: 1.75rem;
    align-items: start;
    border-radius: 0.75rem;
    color: #FCFCFC;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
  }
  
  .free-version-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .start-recruiting-button {
    background-color: #D2FF2F;
    color: #202020;
    border: none;
    padding: 0.6rem 2rem;
    margin-top: 20px;
    border-radius: 0.75rem;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .start-recruiting-button:hover {
    transform: rotate(2deg);
  }
  
  .free-version-cta {
    width: 100%;
    justify-content: flex-end;
    align-content: end;
  }
  