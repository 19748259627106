.long-text-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 70%;
}

.long-text-field-label {
  font-size: 0.8rem;
  color: #202020;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.long-text-field-input {
  min-height: 5rem;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 0.35rem;
  border: 1px solid #20202066;
  color: #202020;
  background: #FCFCFC;
  resize: vertical;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.long-text-field-input:focus {
  border-color: #D2FF2F;
  box-shadow: 0 0 0 3px rgba(210, 255, 47, 0.3);
  outline: none;
}
.long-text-field-label.dark-mode {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #FCFCFC;
  font-weight: 600;
}

.long-text-field-input.dark-mode {
  background-color: #2E2E2E; /* Example dark background */
  color: #fff; /* Light text color for dark backgrounds */
  border-color: #535353; /* Darker border for inputs */
}
