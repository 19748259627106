  .applicant-view-page {
    display: flex;
    min-height: 100vh; /* Minimum height is viewport height */
  }
  
  .applicant-info-application-date{
    color: #202020;
    font-weight: 400;
    margin-bottom: 1.3rem;
    font-size: 1rem;
  }

  .applicant-info-title{
    color: #202020;
    font-size: 2em;
    margin-bottom: 1rem;
    font-weight: 400;
    margin-top:0;
  }

  .overlay-close-button{
    width: 100%;
    background-color: #202020;
    margin-bottom: 1rem;
    border-radius: 0.75rem;
    border: 2px solid #FCFCFC;
    padding: 0.5rem;
    color: #FCFCFC;
    font-weight: 500;
    transition: all ease-in-out 0.2s;
  }

  .overlay-close-button:hover{
    color: #c44242;
    border: 2px solid #c44242;
    transform: translateY(0.4rem) ;
  }

  .applicant-info-close{
    justify-content: center;
  }

  .credential-sections-recruiter{
    align-items: center;
    border-radius: 0.75rem;
    background-color: #FCFCFC;
    width: 33%;
    padding: 2rem;
    min-height: 70rem;
    height: 100%;
  }
  
  .applicant-view-content {
    padding-block: 1rem;
    display: flex;
    gap: 1rem;
    padding-left: 3rem;
    background-color: #202020b0;
    backdrop-filter: blur(20px);
    justify-content: end;
    padding-inline: 2rem;
    right: 0;
  }

  .applicant-overlay {
    position: fixed;
    top: 0;
    right: 1rem;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: start; /* Changed to start to allow for scrolling within the overlay */
    z-index: 1000; /* Ensure it's above other content */
    overflow-y: auto; /* Allow the overlay itself to be scrollable */
  }
  
  /* Ensure the body can't scroll when the overlay is open */
  .body-no-scroll {
    overflow: hidden;
  }

  .met-requirement {
    border: 1px solid #D2FF2F; /* Green border for met requirements */
  }
  
  .unmet-requirement {
    opacity: 0.3;
  }
  
  

  .applicant-info-section{
    margin-inline: 1.6rem;
    padding-block: 1.6rem;
  }

  .applicant-info{
    width: 30vw;
    text-align: start;
    background-color: #FCFCFC;
    border-radius: 0.75rem;
    padding-block: 1.6rem;
    height: fit-content;
  }

  .applicant-info-requirements-title{
    color: #202020;
    font-size: 2em;
    margin-bottom: 1vw;
  }

  .applicant-info-qa-title {
    margin-block: 1rem;
    color: #202020;
  }
  
  .applicant-qa-item {
    border-bottom: 1px solid #2020202e;
    padding-bottom: 1rem;
    margin-bottom: 2vw;
    color: #202020;
    
  }

  .qa-question{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #202020;
  }

  .qa-answer{
    font-size: 1em;
    color: #202020;
  }
  
  .applicant-info-cover-letter-title {
    margin-top: 1.2em;
    margin-bottom: 0.8em;
    color: #202020;
  }
  
  .applicant-cover-letter {
    padding: 1em;
    font-size: 1em;
    color: #202020;
    border: 1px solid #202020;
    border-radius: 0.75rem;
    line-height: 1.4em;
    margin-bottom: 1rem;
  }
  
  .no-questions-display {
    color: #202020;
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 3em;
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes slideInDelayed {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOutDelayed {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  

  .slide-in {
    animation: slideInFromRight 0.3s ease-out forwards;
  }
  
  .slide-out {
    animation: slideOutToRight 0.3s ease-out forwards;
  }
