.addressfield {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 70%;
}

.address-field-label {
    font-size: 0.8rem;
    color: #202020;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

/* Mobile styles */
@media (max-width: 768px) {
    .addressfield {
        width: 100%;
    }
}