.loading-page{
    display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
}

.loading-page-lottie{
    display: block;
}