.create-job-offer-page {
  display: flex;
  position: relative;
  background-color: #20202023;
  width: 30rem;
}

.create-job-offer-content {
  text-align: start;
  background-color: #FCFCFC;
  border-radius: 0.75rem;
  padding: 1.6rem;
  width: 70rem;
  height: 35rem;
  padding-bottom: 5rem;
  overflow: hidden;
  transition: height 0.3s ease-in-out; /* Add this line */
}

.preview-content {
  position: fixed;
  height: 100vh;
  display: flex;
  margin-block: 1rem;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto;
  padding: 5vw;
  background-color: #FCFCFC;
  width: 50rem;
  border-radius: 0.75rem;
}

.create-job-offer-title {
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #202020;
}

.requirements-label {
  font-size: 23px;
  color: #FCFCFC;
  font-weight: 500;
  margin-bottom: 1vw;
}

.type-label {
  font-size: 1rem;
  color: #202020;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.question-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding: 1em;
  border: 1px solid #FCFCFC;
  border-radius: 0.75rem;
  background: rgba(252, 252, 252, 0.1);
  color: #FCFCFC;
}

.question-text {
  font-size: 18px;
  margin-bottom: 0.5em;
}

.question-priority {
  display: flex;
  align-items: center;
}

.question-priority-label {
  font-size: 16px;
  margin-right: 0.5em;
}

.priority-input {
  width: 40px;
  padding: 5px;
  border: 1px solid #FCFCFC;
  border-radius: 0.75rem;
  color: #FCFCFC;
  background: none;
}

.add-job-offer-submit {
  padding: 0.5rem 0.7rem;
  background-color: #202020;
  color: #FCFCFC;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1.2em;
  transition: all 0.3s ease-in-out;
  border: none;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}

.add-job-offer-submit:hover {
  background-color: #D2FF2F;
  color: #202020;
}

.add-job-offer-submit:active {
  background-color: #2c3a46;
  transform: scale(0.98);
}

.salary-range-box {
  align-items: center;
  display: flex;
  gap: 2rem;
}

.salary-range-specify-checkbox label {
  font-size: 0.8rem;
  color: #202020;
  margin-left: 1rem;
}

.create-job-offer-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
  background-color: #FCFCFC;
  backdrop-filter: blur(5px);
  margin: 0;
  transform: none;
}

.close-button-create {
  border: none;
  background-color: #202020;
  padding: 0.5rem 0.9rem;
  color: #FCFCFC;
  border-radius: 0.75rem;
  font-size: 1.2rem;
  position: fixed;
  left: 4rem;
  top: 4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.close-button-create:hover {
  transform: rotate(2deg);
  background-color: rgb(170, 25, 25);
}

.create-job-offer-leftright {
  display: flex;
  height: 100%;
  justify-content: center;
}

.create-job-offer-left,
.create-job-offer-right {
  padding-block: 4rem;
  padding-inline: 4rem;
  width: 50%;
  align-content: center;
}

.create-job-offer-right {
  overflow-y: scroll;
}

.navigation-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.requirement-item-editable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  background-color: #FCFCFC;
  color: #202020;
  border: solid 1px #12121210;
}

.requirement-item-editable button {
  padding: 0.3rem 0.6rem;
  background-color: #D2FF2F;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.requirement-item-editable button:hover {
  background-color: #a8d100;
}

.requirement-item-editable-info {
  display: flex;
  flex-direction: column;
}

/* Animation Classes */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms, transform 300ms;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}
