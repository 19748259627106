@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: help;
    margin-left: 0.8vw;
    background-color: #D2FF2F;
    padding: 0.3vw 0.6vw;
    color: #202020;
    border-radius: 0.75rem;
    font-size: 0.7em;
}

.tooltip-text {
    visibility: hidden;
    opacity: 0; /* set initial opacity to 0 */
    background-color: #fcfcfc8b;
    color: #202020;
    text-align: center;
    backdrop-filter: blur(4px);
    border-radius: 0.75rem;
    padding: 1vw;
    min-width: 13vw;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    width: 120px;
    transform: translateY(20px); /* initial transform for animation start */
    transition: visibility 0.5s, opacity 0.5s, transform 0.5s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1; /* on hover, opacity set to 1 */
    animation: fadeInSlideUp 0.5s forwards; /* apply the animation */
}
