@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.experience-form {
    background: transparent;
    color: #202020;
    border-radius: 0.75rem;
    padding: 20px;
    margin-bottom: 10px;
    min-width: 10vw;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);

  }
  
  .experience-form input, .experience-form button {
    margin-bottom: 10px;
    font-size: 0.8rem;
    border-radius: 0.75rem;
    padding: 2rem;
  }
  
  .experience-form button {
    cursor: pointer;
    background: #D2FF2F;
    border: none;
    color: #202020;
  }

  .yearsexperience-title{
    font-size: x-large;
  
  }

  .ant-select-selection, .ant-checkbox-checked .ant-checkbox-inner, .ant-btn-primary {
    background: transparent !important;
    border-color: #202020 !important;
    border-radius: 0.75rem;
  }

  .requirement-checkbox{
    margin-left: 10px;
    color: #202020;
  }

  .ant-input-number-input{
    font-size: 0.8rem;
    color: #202020;
  }
  
  .ant-tag-checkable-checked {
    background-color: #D2FF2F !important;
    border-color: #D2FF2F !important;
    color: #202020 !important;
  }

  .yearsexperience-title{
    color: #202020;
    font-size: 1rem;
    font-weight: 400;
  }

  .category-title{
    color: #202020;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .ant-form-item-control-input-content{
    margin-top: 10px;

  }

  .add-button-requirement {
    font-size: 0.8em;
    color: #121212;
    padding: 0.6rem;
    border-radius: 0.75rem;
    border: solid 1px #121212;
    background-color: transparent;
    cursor: pointer;
  }
