.add-button {
    background-color: transparent;
    font-size: 39px;
    border: 1px solid #FCFCFC;
    border-radius: 0.75rem;
    width: calc(100% - 10vw);
    cursor: pointer;
    transition: all 0.3s;
    color: #FCFCFC;
  }
  
  .add-button:hover {
    width: calc(100% - 8vw);
    ;
  }