.language-form {
    background: transparent;
    display: flex;
    flex-direction: column;
    border: solid 1px #121212;
    padding: 2rem;
    border-radius: 0.75rem;
    width: 25rem;
    margin-bottom: 1rem;
  }
  
  .language-form select, .language-form button {  /* Changed from input to select */
    margin-bottom: 10px;
    font-size: 0.8rem;
    border-radius: 0.75rem;
    padding: 0.6rem;
  }
  
  .language-form-button {
    cursor: pointer;
    border: 1px solid #202020;
    color: #202020;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.75rem;
  }
  
