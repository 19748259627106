.create-button {
    background-color: #202020;
    font-size: 18px;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    size: 40%;
    border: none;
    border-radius: 0.75rem;;
    cursor: pointer;
    transition: all 0.3s;
    color: #FCFCFC;
  }
  
  .create-button:hover {
    color: #D2FF2F;
    background-color: #202020e9;
  }