.logout-button {
    display: flex;
    align-items: center;
    color: #FCFCFC;
    border: solid 1px transparent;
    margin-bottom: 0.3rem;
    transition: all 0.2s ease-in-out;
    border-radius: 0.5rem;
    padding: 0.4rem;
    cursor: pointer;
    white-space: nowrap;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  .logout-button img{
    width: 1.3rem;
    height: auto;
  }
  
  .logout-button:hover {
    border-color: #FCFCFC;
  }
  
  .logout-button span {
    margin-left: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
  }
  
  .navbar.expanded .logout-button span {
    opacity: 1;
  }