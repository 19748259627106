.max-tags-text{
    font-size: 1.4em;
    margin: 1em;
    color: #202020;
    font-weight: 400;
}

.tags-container {
    width: 100%; 
    display: flex; 
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

.tags-container .ant-tag-checkable {
    margin-right: 8px; /* Add right margin */
    margin-bottom: 8px; /* Add bottom margin */
}

