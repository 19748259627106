.academic-form {
  background: transparent;
  color: #202020;
  border: solid 1px #202020;
  border-radius: 0.75rem;
  padding: 20px;
  margin-bottom: 10px;
  min-width: 10vw;
  display: flex;
  flex-direction: column;
}

.academic-form input, .academic-form button {
  margin-bottom: 10px;
  font-size: 18px;
  border-radius: 0.75rem;
  padding: 10px;
}

.academic-form button {
  cursor: pointer;
  background: #D2FF2F;
  border: none;
  color: #202020;
}

.addbutton-requirement{
  color: #202020;
  font-size: 1rem;
}

.addbutton-requirement:hover{
  color: #FCFCFC;
background-color: #202020;
}