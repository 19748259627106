
.application-pool{
    background-color: #202020;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    border: solid 1px #202020; /* Change the border color on hover */
    padding: 1.4rem;
    display: flex;
    cursor: pointer;
    transition: all 0.3s ease;
    align-items: center;
    color: #FCFCFC;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.25);
    text-decoration: none;
}

.application-pool:hover{
  border: solid 1px #D2FF2F; /* Change the border color on hover */
  transform: rotate(1deg); /* Moves the card up by 5px */
  box-shadow: 0px 10px 20px rgba(210, 255, 47, 0.5); /* Deepens the shadow */
}

.pic-info-pool{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
    color: #FCFCFC;
    margin-right: 3rem;
}


.application-pool .long-text-field-input {
min-height: 4rem;
}


/* Add this to your existing CSS file */
.clickable {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5rem;
    transition: all 0.3s ease; /* Smooth transition for visual feedback */
  }
  
  .clickable img {
    margin-left: 8px;
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    transition: all 0.3s ease; /* Smooth transition for visual feedback */
  }
 
  .application-right-pool {
    display: flex;
    flex-direction: column;
    height: 40%; /* Set the height to allow children to grow */
    width: 30%; /* Set the width as required */
      }
      
      .application-pool .long-text-field {
        flex-grow: 1; /* Grow to use available space */
        width: 100%; /* Occupy the full width of the parent */
        margin-bottom: 1rem; /* Keep the space at the bottom */
      }
  

  .application-left-pool {
    width: 70%;
    height: 100%;
    align-items:flex-start;
    padding-right: 1rem;
 }



.clickable:hover{
    transform: rotate(2deg); /* initial transform for animation start */
    transition: visibility 0.5s, opacity 0.5s, transform 0.5s;
}

.clickable.copied img {
  transform: rotate(20deg);
  }