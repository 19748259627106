.privacy-terms-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #FCFCFC;
    background-color: #121212;
}

.privacy-policy, .terms-of-service {
    margin-bottom: 20px;
}

.privacy-terms-container h2 {
    font-size: 23px;
    margin-bottom: 0.75rem;
    font-weight: 400;
}

.privacy-terms-container h3 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.privacy-terms-container p {
    font-size: 16px;
    margin-bottom: 10px;
}


/* Add more styling rules as needed */
