.requirement-item-view {
    background-color: transparent;
    align-items: center;
    margin-bottom: 1.5vw;
    cursor: pointer;
    transition: all 0.2s;
    color: #202020;
  }

.requirement-item-view:hover{
    margin-right: 1vw;
}

.academic-category-requirement{
    font-size: 20px;
}
  
  .requirement-type {
    color: #202020;
    font-size: 20px;
    font-weight:500;
    margin-bottom: 0.2rem;
  }