/* src/components/BookInterviewButton.css */

.book-interview-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .book-interview-button {
    background-color: #D2FF2F;
    color: #121212;
    border: none;
    padding: 10px 20px;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .book-interview-button:hover {
    transform: rotate(2deg);
  }
  
  .confirmation-card {
    background-color: #FCFCFC;
    color: #121212;
    padding: 20px;
    border-radius: 0.75rem;
    text-align: center;
    animation: fadeIn 0.5s;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);

  }
  
  .confirm-button, .cancel-button {
    background-color: #121212;
    color: #FCFCFC;
    
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 0.75rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .confirm-button:hover, .cancel-button:hover {
    transform: rotate(2deg);
  }
  
  .success-message {
    background-color: #FCFCFC;
    color: #121212;
    border: 1px solid #D2FF2F;
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.5s;
    border-radius: 0.75rem;
    margin-top: 20px;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);

  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  