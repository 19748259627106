.joboffer {
  background-color: #FCFCFC;
  border: 1px solid #fcfcfc4a;
  border-radius: 0.75rem;;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1.1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #202020;
  display: flex; /* Changed to 'flex' to ensure children align properly */
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}


  .joboffer-title{
  color: #202020;
  font-size: 1rem;
  font-weight:bold ;
  max-width: 70%;
  line-height: 117%;
  margin-bottom: 0.5rem;
  }
  
  .joboffer:hover {
    border-color: #202020;
    margin-bottom: 1.4rem;
  }

  .joboffer-applications-title{
    margin: 0;

  }

  .joboffer-applications{
    text-align: center;
  }

  .joboffer-applications-number {
    font-size: 1rem;
    display: flex;
    flex: 1; /* Adjust the flex-grow as needed to match the headers */
    text-align: center; /* Align the number in the center */
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    gap: 0.5rem;
  }

  .joboffer-new-applications{
    background-color: #D2FF2F;
    color: #202020;
    padding: 0.2rem 0.6rem;
    border-radius: 0.75rem;
  }

  
  .joboffer-title-locality {
    max-width: 30%; /* Adjust this as needed */
    flex: 1; /* Allow it to grow */
    margin-right: 1.3rem; /* Add a right margin to create space */
  }
  
  .joboffer-locality-type{
    font-size:0.8rem;
    max-width: 70%; /* Adjust th-is as needed */
  }
  
  .joboffer-activity {
    font-size: 1rem;
    flex: 1; /* Adjust the flex-grow as needed to match the headers */
  }