.refresh-button {
  border: none;
  font-size: 1em;
  background-color: transparent;
  color: #202020;
  cursor: pointer;
  margin-left: 1.3em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-component{
  display: flex;
  align-items: center;
}


.refresh-button.disabled {
  color: #7c7c7c;
  cursor: not-allowed;
}

.countdown {
  color: #7c7c7c;
}

.refresh-icon {
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
  transition: transform 0.5s;
  filter: invert(1);
}

.refresh-button:not(.disabled):active .refresh-icon {
  transform: rotate(360deg);
}
