
.application-top-top{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


 .application-top-bottom {
  display: flex;
  flex-direction: column;
    }


.toggle-symbol{
    color: #20202078;
}
.application-top-latest-experience h3{
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

/* ScoreCircle.css */
.score-circle {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #121212;
    position: absolute;
    top: -1rem;
    right: -1rem;
    border: solid 5px #202020;
}



.application-top-experience-item{
    padding: 0.4rem 1rem;
    transition: all ease-in-out 0.2s;
    border-radius: 0.37rem;
    background-color: #fcfcfc;
    width: fit-content;
}

.application-top-experience-item-text{
    margin-bottom: 0.5rem;
    color: #121212;
    margin: 0;
}
    
    .application-right .long-text-field {
      flex-grow: 1; /* Grow to use available space */
      width: 100%; /* Occupy the full width of the parent */
      margin-bottom: 1rem; /* Keep the space at the bottom */
    }
 
    .application-top {
      background-color: #202020;
      position: relative;
      border-radius: 0.75rem;
      border: solid 1px #202020; /* Change the border color on hover */
      padding: 2rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      gap: 1rem;
      transition: all 0.3s ease;
      color: #FCFCFC;
      box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.25);
    }
    
    .application-top:hover {
        transform: rotate(2deg);
      border: solid 1px #D2FF2F; /* Change the border color on hover */
      box-shadow: 0px 10px 20px rgba(210, 255, 47, 0.5); /* Deepens the shadow */
    }

  .application-name {
    font-size: 1.2em;
    margin-bottom: 0.2em;
    font-weight: 400; /* Medium weight */
    text-decoration: none;
    color: #FCFCFC;
    text-wrap: nowrap;
  }
  
  .application-date {
    font-size: 0.9em;
    font-weight: 300; /* Light weight */
    color: rgba(255, 255, 255, 0.7); /* Assuming your text color is white. Change as needed. */
  }
  
  .application-role {
    font-size: 17px;
    font-weight: 400; /* Regular weight */
    margin-bottom: 0.5rem;
  }


  .application-req-answ-cover{
    color: #FCFCFC;
    font-size: 1em;
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  
  .full-opacity{
    opacity: 1 !important;
  }
  
  .fulfilled-checkmark{
    height: 1.4vw;
    margin-bottom: -0.2vw;
    margin-left: 0.5vw;
  }

  .pic-info-top{
    display: flex;
    gap: 1.3rem;
    margin-right: 6rem;
  }