.credential {
    background-color: transparent;
    border: 1px solid transparent;
    margin-top: 1em;
    border-radius: 0.75rem;
    padding: 1.2rem;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.2s;
    color: #202020;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);

  }
  
  .credential-title{
  color: #202020;
  font-size: 25px;
  font-weight:600 ;
  margin-bottom: 3px;
  
  }

  
  .credential-tags{
  margin-top: 20px;
  font-size: 0.8rem;
  }
  
  .credential:hover {
    border-color: #202020;
  }