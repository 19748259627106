.feedback-container {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.feedback-toggle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;  /* to keep the toggle above the feedback button */
}

.arrow-icon {
    width: 20px;
    height: 20px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M7.05 216.1l7.07-7.07c9.37-9.37 24.57-9.37 33.94 0L224 392.5l176.9-176.9c9.37-9.37 24.57-9.37 33.94 0l7.07 7.07c9.37 9.37 9.37 24.57 0 33.94L247.5 504.5c-9.37 9.37-24.57 9.37-33.94 0L7.05 250a24 24 0 0 1 0-33.9z" fill="white"/></svg>') center/contain no-repeat;
    transform: rotate(270deg); /* initially pointing left */
    transition: transform 0.3s ease-in-out;
}

.arrow-icon.down {
    transform: rotate(90deg); /* pointing right when expanded */
}

.feedback-button {
    padding: 1vw 1.2vw;
    background-color: #333;
    color: #FCFCFC;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    font-size: 1.4em;
    max-width: 0;
    transition: max-width 0.3s ease-in-out;
    visibility: hidden;
    white-space: nowrap;
}

.feedback-container .feedback-button {
    max-width: 200px;  /* Adjust as needed */
    visibility: visible;
}

.feedback-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-modal {
    background-color: #202020;
    padding: 2vw;
    border-radius: 0.75rem;
    box-shadow: 0 0 2vw rgba(255, 255, 255, 0.053);
    width: 80%; /* You can adjust this */
    max-width: 40vw; /* and this based on your design preference */
}

.feedback-modal h2 {
    margin-top: 0;
}

.close-button {
    color: #FCFCFC;
    right: 20px;
    top: 10px;
    background: none;
    border: none;
    font-size: 1.3rem;
    cursor: pointer;
    margin-bottom: 1vw;
}

.feedback-modal textarea {
    width: 100%;
    height: 10vw;
    padding: 1vw;
    resize: none;
    margin-bottom: 0.5vw;
    font-size: 1em;
    color: #FCFCFC;
    background-color: transparent;
    border: solid 1px #FCFCFC;
    border-radius: 0.75rem;
}

.submit-button {
    padding: 0.6vw;
    color: #D2FF2F;
    background-color: transparent;
    border: solid 1px #D2FF2F;
    cursor: pointer;
    font-size: 1em;
    border-radius: 0.75rem;
    width: 80%;
    transition: all 0.2s ease-in-out;
}

.submit-button:hover{
    width: 100%;
    transition: all 0.3;
}

.feedback-modal-title{
    color: #FCFCFC;
    margin-bottom: 0.5vw;
}

.feedback-modal-text{
    color: #FCFCFC;
    margin-bottom: 2vw;
    opacity: 0.7;
}

@media (max-width: 600px) {
    .feedback-button{
        bottom: 2rem;
        right: 2rem;
    }
}
