.selectfield {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 80%;
}

.select-field-label {
    font-size: 0.8rem;
    color: #202020;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.selectfield p{
    margin-top: 0rem;
    font-size: 0.8rem;
}

.select-field-input {
    -webkit-appearance: none;
    appearance: none;
    padding: 0.5rem;
    border: 1px solid #20202066;
    border-radius: 0.35rem;
    background-color: #FCFCFC;
    font-size: 0.8rem;
    color: #202020;
    width: 100%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-field-input:focus {
    border-color: #D2FF2F;
    box-shadow: 0 0 0 3px rgba(210, 255, 47, 0.3);
    outline: none;
}

.select-field-input option {
    color: #24292E;
    background-color: #202020;
}

/* Mobile styles */
@media (max-width: 768px) {
    .selectfield {
        width: 100%;
    }
}