.navbar {
  position: relative;
  height: calc(100vh - 3rem);
  width: 5.125rem; /* Collapsed width */
  border-radius: 0.75rem;
  z-index: 1000; /* Ensure it's on top of other elements */
  overflow: visible;
}

.navbar-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 5.125rem; /* Collapsed width */
  height: 100%;
  padding: 1.5rem 1rem;
  transition: width 0.25s ease-in-out, transform 0.25s ease-in-out;
  background: #202020;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(18, 18, 18, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar.expanded .navbar-content {
  width: 11rem; /* Expanded width */
  transform: translateX(0);
}

.navbar:not(.expanded) .navbar-content {
  transform: translateX(0);
}

.logo {
  width: 2.49494rem;
  height: 2.49488rem;
  flex-shrink: 0;
  margin-bottom: 7rem;
}

.navbar-button {
  display: flex;
  align-items: center;
  color: #FCFCFC;
  border: solid 1px transparent;
  margin-bottom: 0.3rem;
  transition: all 0.2s ease-in-out;
  border-radius: 0.35rem;
  padding: 0.2rem 0.6rem;
  padding-left: 0.7rem;
  cursor: pointer;
  white-space: nowrap;
}

.navbar-button span, .logout-button span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-left: 0.5rem;
}

.navbar.expanded .navbar-button span,
.navbar.expanded .logout-button span {
  opacity: 1;
}

.navbar-button:hover {
  background-color: #292929;
}

.navbar-button.active {
  background-color: #3E3E3E;
}

.navbar-button.active:hover {
  border-color: #3E3E3E;
}

.navbar-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.navbar-logo {
  width: 1.3rem;
  min-width: 1.3rem;
}

.logout-button {
  margin-top: auto;
  padding: 0.4rem;
}

/* Main content adjustment */
.main-content {
  margin-left: 5.125rem; /* Adjust based on collapsed navbar width */
  transition: margin-left 0.3s ease-in-out;
}