.questionList {
  color: #202020;
  border-radius: 0.75rem;
  margin-bottom: 20px;
  transition: all 0.5s ease;
  margin-top: 3vw;
}

.question-content {
  display: flex;
  flex-direction: column; /* Changed to column for better structuring of elements */
  align-items: flex-start; /* Align items to start for column layout */
  margin-bottom: 1vw;
  gap: 0.2rem;
}

.instruction-text { /* Style for the instructional text */
  font-size: 0.9rem; /* Adjust as needed */
  color: #606060; /* Slightly subdued color for instructions */
  margin-bottom: 0.5rem;
}
.choices-adding{
  display: flex;
  gap: 1rem;
}

.question-and-type-container {
  display: flex;
  gap: 1rem; /* Adjust the gap as necessary */
  width: 100%; /* Ensure it takes full width for consistent alignment */
  align-items: end;
}

.question-and-type-container .selectfield{
  width: 20%;
}

.question-and-type-container .textfield{
  width: 60%;
  margin-right: 1rem;
}


.question-add-button {
  background-color: transparent;
  padding: 0.4rem 1rem;
  color: #202020;
  border: solid 1px #202020;
  font-size: 0.8rem;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: end;
  text-wrap: nowrap;
  margin-bottom: 1.5rem;

}

.question-add-button:hover {
  background-color: #D2FF2F;
  border: solid 1px #D2FF2F;
  color: #202020;
}

.choices-grid {
  display: block;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-bottom: 1rem;
  margin-top: -1.5rem;
}

.choice-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #f2f2f2; /* Light grey, adjust as needed */
  border-radius: 0.75rem;
}

.choice-delete-button, .choice-required-checkbox {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #202020;
  font-weight: 500;
}

.questionArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.questionItem {
  display: flex;
  flex-direction: column;
}

.questionItem h3 {
  font-size: 1rem;
  color: #202020;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.questionItem ul {
  color: #202020;
  max-width: 20vw;  /* Limiting the width to 480px */
  padding: 0;
}





.questionItem-li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #202020;
  font-size: 0.8rem;
  padding: 0.5rem 2rem;
  display: flex;
  border-left: solid 1px #202020;
  margin-bottom: 15px;
  transition: all 0.2s;  
  margin-bottom: 1vw;  /* Adding some gap between the items */
  word-wrap: break-word;  /* Break the word to next line if it exceeds the width */
}

.remove-item {
  cursor: pointer;
  color: red;
}

.item-title {
    font-size: 23px;
    color: #202020;
    font-weight: 500;
    margin-bottom: 1vw;
}
.add-item-textfield{
  width: 60%;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.add-item-button {
    border: none;
    border-radius: 0.75rem;
    color: #202020;
    background-color: #d4d4d4;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0.5rem;
    margin-left: 1rem;
    transition: all 0.3s;
}

.add-item-button:hover {
  background-color: #bbbbbb;
}

.item-delete-button{
border: solid 1px #202020;
border-radius: 0.75rem;
color: #202020;
background-color: transparent;
cursor: pointer;
font-size: 0.8rem;
padding: 0.5rem;
margin-left: 0.6rem;
transition: all 0.3s;
}

.item-delete-button:hover{
color: #a73810;
border: solid 1px #a73810;
}


.questionItem {
  color: #202020;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-left: solid 2px #202020;
  margin-bottom: 15px;
  transition: all 0.2s;
}

.questionItem:hover {
  margin-left: 1vw;
}

.questionItem h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3px;
  margin-left: 0.5vw;
}

.questionItem p {
  margin-left: 0.5vw;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
}

.question-delete-button {
  border: solid 1px #202020;
  padding: 0.3rem;
  background-color: transparent;
  color: #202020;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 0.75rem;
  margin-top: 0.7vw;
  margin-left: 0.5vw;
  font-size: 0.8rem;
  font-weight: bold;
  width: 7vw;
}

.question-delete-button:hover {
  color: #ff4500;
  border: solid 1px #ff4500;
}

.reject-condition {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

.reject-condition label {
  margin-bottom: 0.5rem;
}

.reject-condition div {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.reject-condition input {
  margin-right: 0.5rem;
}
