.collapsible-filter-section .collapsible-filter-header {
    background-color: transparent;
    color: #202020;
    cursor: pointer;
    border: none;
    padding-bottom: 0.5rem;
    border-radius: 0.75rem;
    width: 85%;
    margin-bottom: 0.8rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .collapsible-filter-section .arrow {
    display: inline-block;
    margin-left: 5px;
  }
  
  .collapsible-filter-section .up::after {
    content: '▲'; /* Unicode up arrow */
  }
  
  .collapsible-filter-section .down::after {
    content: '▼'; /* Unicode down arrow */
  }

  .collapsible-filter-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; /* Adjust timing to your liking */
  }
  
  .collapsible-filter-section.open .collapsible-filter-content {
    max-height: 500px; /* Adjust based on the maximum expected content height */
    /* For a more dynamic approach, you might need to adjust this value with JavaScript based on the content's actual height */
  }
  
  