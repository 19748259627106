.tag{
    background-color: #e0e0e0;
    padding: 0.3em;
    padding-inline: 1em;
    border-radius: 0.75rem;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #202020;
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 500;
  }

.tag p{
  margin-block: 0px;
}