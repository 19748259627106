.linkedin-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    opacity: 0.7;
    transition: transform 0.3s ease, opacity 0.3s ease;
    margin-top: 1rem;
  }
  
  .linkedin-button:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  
  .linkedin-svg {
    width: 24px;
    height: 24px;
  }
  