
  
  .empty-circle {
    border: 2px solid #202020;
  }
  
  .filled-circle {
    background-color: #202020;
  }

  .no-bullets{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .requirement-row {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  
  .requirement-text {
    margin-left: 0.3vh; 
    color: #202020;
    font-size: 1.2em;
  }
  