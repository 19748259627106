.settings-label{
    color: #FCFCFC;
    font-size: 1.2em;
    opacity: 0.7;
    display: grid;
    margin-bottom: 0.3vw;
}

.recruiter-settings-page{
  display: flex;
  padding: 1.5rem;
  gap: 0.87rem;
  height: 100vh; /* or any specific height */}

.recruiter-settings-content{
    padding: 5vw;
    background-color: #FCFCFC;
    border-radius: 0.75rem;
}

.recruiter-settings-title{
    font-size: 4em;
    font-weight: 500;
    margin-bottom: 3vw;
    color: #202020;
}

.settings-save-button{
    display: block;
    padding: 8px 10px;
    background-color: transparent;
    color: #202020;
    border: solid 1px #202020;
    border-radius: 0.75rem;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0.6rem;
  }

  .settings-remove-data-button{
    display: grid;
    padding: 8px 10px;
    background-color: transparent;
    color: #C74F4F;
    border: solid 1px #C74F4F;
    border-radius: 0.75rem;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0.6rem;

  }

  .settings-remove-data-button:hover {
    background-color: #C74F4F;
    color: #FCFCFC;
    }
  
    .settings-save-button:hover {
        background-color: #202020;
        color: #FCFCFC;
    }

    .settings-download-data-button{
      display: grid;
      padding: 8px 10px;
      background-color: transparent;
      color: #FCFCFC;
      border: solid 1px #FCFCFC;
      border-radius: 0.75rem;
      cursor: pointer;
      font-size: 1em;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0.6rem;
    }

    .settings-download-data-button:hover {
      background-color: #FCFCFC;
      color: #202020;
    }


  
  .settings-save-button:active {
    background-color: #2c3a46;
    transform: scale(0.98);
  }

  .settings-wrapper {
    display: flex;
    min-height: 100vh;
    
  }
  
  .settings-navigation {
    flex: 1;
    background-color: #FCFCFC;
    padding: 1.2rem;
    border-radius: 0.75rem;
  }
  
  .nav-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    
  }
  
  .nav-item.active {
    background-color: #202020;
    color: #FCFCFC;
    border-radius: 0.75rem;
  }

  .manage-billing-button{
    padding: 0.4rem 1rem;
    background-color: #D2FF2F;
    color: #202020;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    font-size: 0.8rem;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0.6rem;
  }

  .manage-billing-button:hover {
    transform: rotate(2deg);
  }
  
  .settings-content {
    flex: 3;
    padding: 2rem;
    border-radius: 0.75rem;
    background-color: #FCFCFC;
  }
  
  /* Plan Promotion Card Styles */
.plan-promotion-card {
  background-color: #FCFCFC;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 20px 0;
  border: solid 1px #12121220;
}

.best-value-tag {
  top: 0;
  right: -10px;
  background-color: #D2FF2F; /* Gold color for 'Best Value' tag */
  color: #121212;
  padding: 0.5rem 1.2rem;
  border-radius: 0 0 0.75rem 0.75rem;
}

.plan-name {
  font-size: 1.5em;
  color: #333;
  margin: 20px 0 5px 0;
}

.early-adopter-tag {
  color: #121212;
  font-size: 0.7em;
  border: solid 1px #121212;
  padding: 0.2rem 0.5rem;
  margin-left: 10px;
  border-radius: 0.4rem;
  white-space: nowrap;
}

.features-container{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1rem;
  text-wrap: nowrap;
}

.plan-promotion-card button {
  background-color: #D2FF2F; /* Green for buttons */
  color: #202020;
  border: none;
  padding: 0.8rem 2rem;
  margin-top: 20px;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s ease;
}

.plan-promotion-card button:hover {
  background-color: #2ecc71; /* Lighter green on hover */
}

.plan-card-right{
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.plan-card-left{
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-block: 2rem;
}

.price-container {
  display: flex;
  align-items: baseline; /* Aligns the price and the period baseline */
  margin-bottom: 1rem;
}

.plan-price {
  font-size: 3rem;
  font-weight: bold;
  color: #121212;
}

.price-period {
  font-size: 0.9em; /* Smaller font size for "/month" */
  margin-left: 5px; /* Space between the price and "/month" */
  color: #121212; /* More subdued color for the period */
}
