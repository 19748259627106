.post-employer-title{
    font-size: 3.5rem;
    line-height: 123%;
    font-weight: 500;
    margin-bottom: 1rem;
}

.post-employer-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.welcome-employer-modal{
    background-color: #FCFCFC;
    border-radius: 0.75rem;
    justify-content: center;
    align-content: center;
    width: 60rem;
    height: 30rem;
}

.employer-creation-info-text{
    color: #121212;
    width: 85%;
}

.employer-creation-info{
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 49%;
    padding-left: 6rem;
    padding-block: 1rem;
    padding-right: 5vh;

}

.employer-creation-form{
        width: 49%;
        padding-left: 5vh;
        border-left: solid 0.3px #2020201b;
        min-height: 20rem;
        padding-right: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
}
/* Additional CSS for sliding animations */
.slide-enter {
    opacity: 0;
    transform: translateX(100%);
    visibility: hidden;  /* Start hidden */
    position: absolute;  /* Start off the normal document flow */
    width: 100%;  /* Maintain width */
  }
  
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
    visibility: visible;  /* Change to visible when transition starts */
    position: relative;  /* Bring back to flow once visible */
  }
  
  .slide-exit {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
    position: relative;
  }
  
  .slide-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
    visibility: hidden;  /* Hide after transition */
    position: absolute;  /* Remove from flow when exiting */
  }
  
  
  
  .next-button-employer{
    padding: 1.2vh 1.6vw;
    background-color: #D2FF2F;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    color: #202020;
    font-size: 0.8rem;
}

.next-button-employer:hover{
    transform: rotate(2deg);
}

.back-button-employer{
    padding: 1.2vh 1.6vw;
    background-color: #d4d4d4;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    color: #202020;
    font-size: 0.8rem;
}

.back-button-employer:hover{
    transform: rotate(2deg);
}

.employer-creation-buttons{
    display: flex;
    gap: 1rem;
}

.terms-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.applicant-creation-title {
    color: #FCFCFC;
    font-size: 3em;
    margin-bottom: 2vh;
}

.applicant-creation-contents {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap; /* Allow the content to wrap to the next line if there's not enough space */
}

.applicant-creation-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 49%;
    padding-top: 8vh;
    border-right: solid 1px #fcfcfcb1;
    padding-left: 11vh;
    padding-right: 1vh;
}

.applicant-creation-info-logo {
    width: 70%;
    height: auto;
    margin-bottom: 15px;
    align-self: flex-start;
}

.applicant-creation-form {
    width: 49%;
    padding-left: 5vh;
}

.applicant-creation-info-text {
    font-size: 1.1em;
    color: #FCFCFC;
    opacity: 0.7;
    width: 80%;
    line-height: 1.4em;
}

.custom-divider {
    height: 1%;
    width: 1px;
    background-color: grey;
    margin: 0 2%;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1vh;
}

.modal-back-button {
    padding: 1.5vh 2.4vw;
    background-color: #919191;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    color: #202020;
    font-weight: bold;
    font-size: 1.5vh;
}

.modal-back-button:hover {
    background-color: #c2c2c2;
    transform: scale(1.05);
}

.modal-continue-button {
    padding: 1.5vh 2.4vw;
    background-color: #D2ff2f;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    color: #202020;
    font-weight: bold;
    font-size: 1.5vh;
}

.modal-continue-button:hover {
    background-color: #B8E025;
    transform: scale(1.05);
}

.privacy-and-terms {
    color: #202020;
    padding-left: 10px; /* This will create a gap between the checkbox and label text */

}

.privacy-and-terms a {
    color: #202020;
    text-decoration: none;
}

.privacy-and-terms a:hover {
    text-decoration: underline;
}

.privacy-policy-acceptance {
    margin-bottom: 0.5rem;
}


@media (max-width: 1000px) {
    .applicant-creation-info, 
    .applicant-creation-form {
        padding-left: 4vh;
        padding-right: 4vh;
    }

    .applicant-creation-info-text {
        font-size: 1em;
        width: 80%;
    }
}

@media (max-width: 800px) {  
    .applicant-creation-contents {
        flex-direction: row;
    }

    .applicant-creation-info, 
    .applicant-creation-form {
        width: 100%;
        padding-left: 2vh;
        padding-right: 2vh;
        padding-top: 2vh;
    }

    .applicant-creation-info {
        border-right: none;
        padding-bottom: 2vh;
    }

    .applicant-creation-info-text {
        font-size: 0.9em;
        width: 90%;
    }

    .applicant-creation-info-logo {
        width: 80%;
    }
}
